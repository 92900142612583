import * as React from "react"
import Layout from "../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'

const WorkPage = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'work-page whitemain'
    }}
/>
  <Layout pageTitle="Work">
    <section id="main">
      <div className="text-content">
        <h1 className="reveal-textDIS"><span className="revealblue">We’re coders creating websites and web applications for various range of clients and agencies.</span></h1>
        <p><span className="revealblack">We produce high effective work that rewards our clients and agencies with a long term goal and commitment to delivering the best possible user experiences.</span></p>
      </div>
      <div className="clients">
        <div className="clients-logos">
          <div className="logo">
            <img className="picture" src={'/img/works/qvm.svg'} alt="QVM" />
          </div>
          <div className="logo">
            <img className="picture" src={'/img/works/elaa.svg'} alt="ELAA" />
          </div>
          <div className="logo">
            <img className="picture" src={'/img/works/langley.png'} alt="Langley Group" />
          </div>
          <div className="logo">
            <img className="picture" src={'/img/works/wemov.svg'} alt="WeMove" />
          </div>
          <div className="logo">
            <img className="picture" src={'/img/works/smm-dark.svg'} alt="SMM" />
          </div>
          <div className="logo">
            <img className="picture" src={'/img/works/history-week.svg'} alt="History Month" />
          </div>
        </div>
        <div className="bottom-text">
          <p>...also collaborating with design, advertising and marketing agencies.</p>
        </div> 
      </div>
    </section>

    <section id="mission">

      <div className="mission">
        <h2>
        <span className="reveal">We are proud of our work and love what we do. Our work is either delivered by us alone or collaborating with clients or designers.</span>
        </h2>
      </div>

    </section>

    <section id="work">
      <div className="work">


        <AniLink cover to="/work/qvm-winter-night-market" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage: 'url(/img/works/project-featured-qvm-nightmarket.png)'}}>
          </div>
        </AniLink>
        <AniLink cover to="/work/bmg" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage: 'url(/img/works/bmg_feature.jpg)'}}>
          </div>
        </AniLink>


        <AniLink cover to="/work/south-melbourne-market" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture hasSVG">
            <img src={'/img/works/smm.svg'} alt="South Melbourne Market" />
          </div>
        </AniLink>

        <AniLink cover to="/work/we-make-online-videos" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/wemov-feature2.jpg)'}}>
          </div>
        </AniLink>
        <AniLink cover to="/work/bulk-buys" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/bulkbuys.svg)'}}>
          </div>
        </AniLink>

        <AniLink cover to="/work/early-learning-association-australia" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/elaa-featured.png)'}}>
          </div>
        </AniLink>

        <AniLink cover to="/work/bills-forecast" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/bill-forecast-feature.jpg)'}}>
          </div>
        </AniLink>


        <AniLink cover to="/work/history-month" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/history-week-horses.jpg)'}}>
          </div>
        </AniLink>

        <AniLink cover to="/work/word-of-mouth-technology" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/wom-featured-1.jpg)'}}>
          </div>
        </AniLink>

        <AniLink cover to="/work/langley-group" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/langley_feature2.jpg)'}}>
          </div>
        </AniLink>
        <AniLink cover to="/work/braille-sign-supplies" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/braillie-featured.jpg)'}}>
          </div>
        </AniLink>
        <AniLink cover to="/work/asapd" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/asapd.jpg)'}}>
          </div>
        </AniLink>

        <AniLink cover to="/work/promentor" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/promentor.jpg)'}}>
          </div>
        </AniLink>

        <AniLink cover to="/work/sports-excellence-scholarship-funds" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/sesf.png)'}}>
          </div>
        </AniLink>

     </div>
    </section>
</Layout>
  </>
  )
}

export default WorkPage


export const Head = () => (
  <>
    <title>Work - Tobstar</title>
    <meta name="description" content="We are proud of our work and love what we do. Our work is either delivered by us alone or collaborating with agencies." />
  </>
)